import {ROOT_PATH} from '@/components/pages/oberlo/constants/index';
export const IMAGE_TAB_INDEX = -1;

export const ARTICLE_IMAGE_SIZES = {
  height: 513,
  width: 912,
};

export const OBERLO_BLOG_URL = `${ROOT_PATH}/blog`;
export const OBERLO_BLOG_PAGE_URL = `${ROOT_PATH}/blog/page`;

export const FETCHER_STATE = {
  idle: 'idle',
  loading: 'loading',
};
