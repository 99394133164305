import {useParams} from '@remix-run/react';
import {useRef} from 'react';

import Section from '@/components/pages/oberlo/components/Section/Section';
import LatestArticlesList from '@/components/pages/oberlo/components/LatestArticlesList/LatestArticlesList';
import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import {useInfiniteScroll} from '@/components/pages/oberlo/hooks/useInfiniteScroll';
import {useTranslations} from '@/hooks/useTranslations';

import type {ShopifyOberloData, OberloTopic} from '../types';

interface BlogContentMobileProps {
  articles: ShopifyOberloData[];
  className?: string;
  fetchUrl: string;
  topics: OberloTopic[];
  hasNextPage: boolean;
}

export default function BlogContentMobile({
  articles,
  className,
  fetchUrl,
  topics,
  hasNextPage,
}: BlogContentMobileProps) {
  const {t} = useTranslations();
  const params = useParams();
  const isFistPage = !params.page;
  const loader = useRef<HTMLDivElement>(null);
  const {data} = useInfiniteScroll({
    fetchUrl: fetchUrl,
    elementRef: loader,
    initialData: articles,
    hasNextPage,
  });

  return (
    <Section className={className}>
      <LatestArticlesList
        topics={topics}
        articlesData={data}
        isLastPage={!hasNextPage}
        showLatest={isFistPage}
      >
        {isFistPage && (
          <OberloTypography
            as="h2"
            className="text-display-sm font-display uppercase pt-12"
          >
            {t('recentPostsTitle')}
          </OberloTypography>
        )}
      </LatestArticlesList>
      <div ref={loader} />
    </Section>
  );
}
