import {useRef} from 'react';
import {useParams} from '@remix-run/react';

import Section from '@/components/pages/oberlo/components/Section/Section';
import LatestArticlesList from '@/components/pages/oberlo/components/LatestArticlesList/LatestArticlesList';
import OberloTypography from '@/components/pages/oberlo/components/OberloTypography/Typography';
import {useShowMore} from '@/components/pages/oberlo/hooks/useShowMore';
import {useTranslations} from '@/hooks/useTranslations';
import type {
  Pagination,
  ShopifyOberloData,
  OberloTopic,
} from '@/components/pages/oberlo/types';
import {
  baseButtonStyles,
  buttonPrimaryStyle,
} from '@/components/pages/oberlo/styles/styles';
import {twMerge} from '@/stylesheets/twMerge';

import {FETCHER_STATE} from './constants';

interface BlogContentProps {
  className?: string;
  articles: ShopifyOberloData[];
  fetchUrl: string;
  topics: OberloTopic[];
  pagination: Pagination;
}

export default function BlogContent({
  articles,
  className,
  fetchUrl,
  topics,
  pagination,
}: BlogContentProps) {
  const {t} = useTranslations();
  const buttonRef = useRef<HTMLButtonElement>(null);
  const params = useParams();
  const isFistPage = !params.page;
  const {data, fetcherState, handleShowMore} = useShowMore({
    fetchUrl: fetchUrl,
    elementRef: buttonRef,
    initialData: articles,
  });

  return (
    <Section className={className}>
      <LatestArticlesList
        topics={topics}
        articlesData={data}
        isLastPage={!pagination?.hasNextPage}
        showLatest={isFistPage}
      >
        {isFistPage && (
          <OberloTypography
            as="h2"
            className="text-display-sm font-display uppercase pt-12"
          >
            {t('recentPostsTitle')}
          </OberloTypography>
        )}
      </LatestArticlesList>
      {pagination?.hasNextPage && (
        <div id="show-more-btn" className="py-6 flex justify-center">
          <button
            className={twMerge(
              baseButtonStyles,
              buttonPrimaryStyle,
              'w-64 px-8 py-4 btn-primary',
            )}
            onClick={handleShowMore}
            ref={buttonRef}
          >
            {fetcherState === FETCHER_STATE.idle
              ? t('pagination.loadMoreCta')
              : t('pagination.loadingMoreCta')}
          </button>
        </div>
      )}
    </Section>
  );
}
