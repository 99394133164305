import Section from '@/components/pages/oberlo/components/Section/Section';
import GlobalArticle from '@/components/pages/oberlo/components/Article/GlobalArticle';
import TopicHeader from '@/components/pages/oberlo/components/TopicHeader/TopicHeader';
import BlogContent from '@/components/pages/oberlo/Blog/BlogContent';
import BlogContentMobile from '@/components/pages/oberlo/Blog/BlogContentMobile';
import {useTranslations} from '@/hooks/useTranslations';
import {useSiteData} from '@/hooks/useSiteData';
import type {
  Pagination,
  ShopifyOberloData,
  OberloTopic,
} from '@/components/pages/oberlo/types';
import {getShopifyArticleData} from '@/components/pages/oberlo/Home/utils/GetArticleData';
import {HeadlineSchema} from '@/components/pages/oberlo/components/HeadlineSchema/HeadlineSchema';
import {SchemaPublisherInfo} from '@/components/pages/oberlo/components/SchemaPublisherInfo/SchemaPublisherInfo';

import {
  IMAGE_TAB_INDEX,
  ARTICLE_IMAGE_SIZES,
  OBERLO_BLOG_URL,
  OBERLO_BLOG_PAGE_URL,
} from './constants';
import {getBreadcrumbsList} from './utils/utils';

interface BlogProps {
  data: ShopifyOberloData[];
  className?: string;
  showFullArticle?: boolean;
  topics: OberloTopic[];
  pagination: Pagination;
}

export default function Blog({
  data,
  className,
  showFullArticle = true,
  topics,
  pagination,
}: BlogProps) {
  const {site, canonicalUrl} = useSiteData();
  const [firstArticle, ...articles] = data;
  const {t} = useTranslations();
  const lang = site?.locale !== 'en' ? `/${site?.pathPrefix}` : '';
  const first = getShopifyArticleData({
    articleData: firstArticle,
    topics,
    lang,
  });
  const breadcrumbsList = getBreadcrumbsList(t);
  const blogUrl = `${lang}${OBERLO_BLOG_URL}`;
  const blogPageUrl = `${lang}${OBERLO_BLOG_PAGE_URL}`;

  return (
    <Section className={className}>
      <div
        className="bg-gray-10 flex justify-center w-full"
        itemScope
        itemType="https://schema.org/WebPage"
      >
        <HeadlineSchema
          locale={site?.locale || ''}
          mainEntityOfPage={canonicalUrl || ''}
          title={t('htmlHead.pageTitle')}
          description={t('htmlHead.metaDescription')}
        />
        <SchemaPublisherInfo canonicalUrl={canonicalUrl || ''} />
        {showFullArticle ? (
          <GlobalArticle
            {...first}
            t={t}
            header={t('heroTitle')}
            blogTitle={t('blogTitle')}
            blogLink=""
            breadcrumbsList={breadcrumbsList}
            styles={{
              titleStyle: 'text-heading-lg font-sans',
              linkStyles: 'md:col-span-2 bg-gray-10 sm:px-8 md:px-0',
              pictureStyles: 'bg-cover overflow-hidden h-auto',
              articleStyle: 'px-8 md:px-0',
            }}
            imageProps={
              first.imageProps
                ? {
                    ...first.imageProps,
                    ...ARTICLE_IMAGE_SIZES,
                    src: first?.imageProps?.src || '',
                    tabIndex: IMAGE_TAB_INDEX,
                    loading: 'lazy',
                  }
                : undefined
            }
            className="bg-gray-10 container w-full mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 md:mb-0 py-12 px-8 lg:px-[5.625rem]"
          />
        ) : (
          <div className="bg-gray-10 w-full py-12 container mx-auto grid grid-cols-1 md:grid-cols-3 gap-8 md:gap-12 md:mb-0 lg:px-[5.625rem]">
            <TopicHeader
              className="px-8 md:px-12 lg:px-0 md:col-span-2"
              header={t('heroTitle')}
              breadcrumbsList={breadcrumbsList}
              paginationProps={{
                pagination,
                baseUrl: blogUrl,
                secondaryUrl: blogPageUrl,
              }}
            />
          </div>
        )}
      </div>
      <BlogContent
        pagination={pagination}
        topics={topics}
        articles={showFullArticle ? articles : data}
        className="hidden md:block pb-24"
        fetchUrl={blogPageUrl}
      />
      <BlogContentMobile
        hasNextPage={pagination?.hasNextPage}
        topics={topics}
        articles={showFullArticle ? articles : data}
        className="block md:hidden pb-24"
        fetchUrl={blogPageUrl}
      />
    </Section>
  );
}
